<template>
  <div v-if="isEdit" class="lpr-setting">
    <div class="title">
      <img src="@/assets/icons/recognition-yellow.svg" alt="">
      <span>{{ $t('ai_recognition_setting')/*辨識設定*/ }}</span>
    </div>
    <div class="content" :class="{ 'patrol-car-edit': true }">
      <div class="tabs">
        <div class="tab" :class="{ selected: showParameters }" @click="showParameters = true">
          {{ $t('parameter') }}
        </div>
        <div 
          class="tab" 
          :class="{selected: !showParameters}" 
          @click="showParameters = false">
          CH1
        </div>
      </div>
      <div v-if="showParameters" class="tab-content" :class="{ edit: isEdit }">
        <SelectSetter v-model="aiModelType" :data="configLpr.aiModelType" :enabled="canSetting" />
        <div class="line"></div>
        <span class="sub-title">{{ $t('event')/*事件*/ }}</span>
        <SwitchSetter v-model="config.eventPostMode" :data="configLpr.eventPostMode" :enabled="canSetting" />
        <SelectSetter v-model="aiBoxTask.notifyFilterMode" :data="configLpr.notifyFilterMode" :enabled="canSetting" />
        <SliderSetter v-model="config.eventDuration" :data="configLpr.eventDuration" :enabled="canSetting" />
        <SliderSetter v-model="config.repostDataStoreLimit" :data="configLpr.repostDataStoreLimit" :enabled="canSetting" />
      </div>
      <div v-else class="tab-content" :class="{ edit: isEdit }">
        <SliderSetter v-model="config.lprFrequency" :data="configLpr.lprFrequency" :enabled="canSetting" />
        <SliderSetter v-model="config.lprSizeRatio" :data="configLpr.lprSizeRatio" :enabled="canSetting" />
        <div v-if="isEdit" class="wrap-edit-roi">
          <div v-if="!isEditRoi" class="edit-roi" @click="editRoi">
            <img src="@/assets/icons/Crop.svg" alt="">
            <span>{{ $t('ai_roi_set')/*調整辨識範圍*/ }}</span>
          </div>
          <div v-else class="edit-roi" @click="lockRoi">
            <img src="@/assets/icons/lock.svg" alt="">
            <span>{{ $t('ai_roi_lock')/*鎖定辨識範圍*/ }}</span>
          </div>
          <div v-if="isEditRoi" class="reset-roi" @click="resetRoi">
            <img src="@/assets/icons/refresh-white.svg" alt="">
          </div>
        </div>
        <div class="expert-mode" @click="showAdvSettings = !showAdvSettings">
          <img v-if="showAdvSettings" src="@/assets/icons/Arrow_down.svg" alt="">
          <img v-else class="arrow-right" src="@/assets/icons/Arrow_down.svg" alt="">
          <img src="@/assets/icons/graduation-cap.svg" alt="">
          <span>{{ $t('ai_advanced_settings')/*專家模式*/ }}</span>
        </div>
        <template v-if="showAdvSettings">
          <SwitchSetter v-model="config.detectMode" :data="configLpr.detectMode" :enabled="canSetting" />
          <SliderSetter v-model="config.confirmCount" :data="configLpr.confirmCount" :enabled="canSetting" />
          <SliderSetter v-model="config.acceptDiffNum" :data="configLpr.acceptDiffNum" :enabled="canSetting" />
          <SliderSetter v-model="config.indent" :data="configLpr.indent" :enabled="canSetting" />
          <SwitchSetter v-if="aiModelType === 'jp'" v-model="config.isCompleteMode" :data="configLpr.isCompleteMode" :enabled="canSetting" />
        </template>
      </div>
    </div>
  </div>
  <div v-else class="lpr-setting">
    <div class="title">
      <img src="@/assets/icons/recognition-yellow.svg" alt="">
      <span>{{ $t('ai_recognition_setting')/*辨識設定*/ }}</span>
    </div>
    <div class="content">
      <div class="tabs">
        <div class="tab" :class="{ selected: showParameters }" @click="showParameters = true">
          {{ $t('parameter') }}
        </div>
        <div 
          class="tab" 
          :class="{selected: !showParameters}" 
          @click="showParameters = false">
          CH1
        </div>
      </div>
      <div v-if="showParameters" class="tab-content">
        <ConfigItem v-model="aiModelType" :data="configLpr.aiModelType" :showTooltip="false" />
        <div class="line"></div>
        <span class="sub-title">{{ $t('event')/*事件*/ }}</span>
        <ConfigItem v-model="config.eventPostMode" :data="configLpr.eventPostMode" :showTooltip="false" />
        <ConfigItem v-model="aiBoxTask.notifyFilterMode" :data="configLpr.notifyFilterMode" />
        <ConfigItem v-model="config.eventDuration" :data="configLpr.eventDuration" />
        <ConfigItem v-model="config.repostDataStoreLimit" :data="configLpr.repostDataStoreLimit" />
        <div class="line"></div>
        <div class="time">
          <span>{{ $t('ai_updated_time')/*更新時間*/ }}</span>
          <span>{{ formatTime(aiBoxTask.updatedTime) }}</span>
        </div>
      </div>
      <div v-else class="tab-content">
        <ConfigItem v-model="config.lprFrequency" :data="configLpr.lprFrequency" />
        <ConfigItem v-model="config.lprSizeRatio" :data="configLpr.lprSizeRatio" />
        <ConfigItem v-model="config.detectMode" :data="configLpr.detectMode" />
        <ConfigItem v-model="config.confirmCount" :data="configLpr.confirmCount" />
        <ConfigItem v-model="config.acceptDiffNum" :data="configLpr.acceptDiffNum" />
        <ConfigItem v-model="config.indent" :data="configLpr.indent" />
        <ConfigItem v-if="aiModelType === 'jp'" v-model="config.isCompleteMode" :data="configLpr.isCompleteMode" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { configLpr } from '@/config/configLpr'
import { formatTime } from '@/utils/lib.js'

export default {
  name: 'LprSettingAibox',
  components: {
    SwitchSetter: () => import('@/components/AiBox/base/SwitchSetter.vue'),
    SliderSetter: () => import('@/components/AiBox/base/SliderSetter.vue'),
    SelectSetter: () => import('@/components/AiBox/base/SelectSetter.vue'),
    ConfigItem: () => import('@/components/AiBox/base/ConfigItem.vue'),
  },
  data() {
    return {
      configLpr,
      showParameters: true,
      showAdvSettings: false,
      isEditExpertMode: false,
      isViewExpertMode: false,
    }
  },
  computed: {
    ...mapState('aibox', ['isEdit', 'isEditRoi']),
    ...mapGetters('aibox', ['aiBoxTask']),
    aiModelType: {
      get() {
        return this.aiBoxTask.config.aiModelType
      },
      set(val) {
        this.updateAiModelType(val)
      },
    },
    lprModelType() {
      // lprTw, lprJp
      return 'lpr' + this.aiModelType.slice(0, 1).toUpperCase() + this.aiModelType.slice(1)
    },
    canSetting() {
      return !this.isEditRoi
    },
    config: {
      get() {
        return this.aiBoxTask.config[this.lprModelType]
      },
      set(data) {
        const payload = {
          modelType: this.lprModelType,
          data: data
        }
        this.updateConfig(payload)
      },
    },
  },
  mounted() {
    
  },
  methods: {
    ...mapMutations('aibox', ['updateIsEditRoi', 'updateAiModelType', 'updateConfig', 'updateROI']),
    formatTime,
    editRoi() {
      this.updateIsEditRoi(true)
    },
    lockRoi() {
      this.updateIsEditRoi(false)
    },
    resetRoi() {
      this.updateROI({
        x1: 0,
        y1: 0,
        x2: 1,
        y2: 1,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
* {
  user-select: none;
}
.lpr-setting {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .title {
    display: flex;
    align-items: center;
    font-size: px2rem(16);
    line-height: 24px;
    font-weight: 700;
    color: #FFE99F;
    margin-bottom: 8px;
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }

  .img-src {
    padding: 6px 12px;
    margin-bottom: 6px;
    background: #39425D;
    border-radius: 8px ;
  }
  
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 46px);
    box-sizing: border-box;
    position: relative;

    &.patrol-car-edit {
      height: calc(100% - 46px - 100px);
    }
    
    .tabs {
      display: flex;
      border-bottom: 1px solid #ffffff33;

      .tab {
        color: #ffffff;
        font-size: 16px;
        line-height: 24px;
        padding: 6px 20px;
        opacity: 0.5;
        cursor: pointer;
        &.selected {
          border-radius: 8px 8px 0 0;
          background: #39425D;
          opacity: 1;
        }
      }
    }

    .tab-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      padding: 12px 4px 12px 12px;
      background: #39425D;
      overflow-y: scroll;
      // border-radius: 0 0 8px 8px; --> 這行會使內容模糊！？
      &.edit {
        background: #151B35;
      }

      .sub-title {
        color: #FFE99F;
        font-weight: 700;
      }
    }

    .line {
      width: 100%;
      height: 1px;
      border-bottom: 1px solid #ffffff80;
    }
    
    .expert-mode {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      color: #FFE99F;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        @include filter_FFE99F;
        &.arrow-right {
          transform: rotate(-90deg);
        }
      }
    }
    .mode {
      color:#ffffff80;
    }

    .time {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        color: #ffffff80;
        font-size: px2rem(14);
      }
      span:first-child {
        font-size: px2rem(16);
      }
    }
  }
}

.wrap-edit-roi {
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-bottom: 12px;
  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }
}
.edit-roi {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  padding: 4px 12px;
  background: #4A5C78;
  border-radius: 8px;
  cursor: pointer;
  
  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}
.reset-roi {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  background: #4A5C7880;
  cursor: pointer;
  &:hover {
    background: #4A5C78;
  }
}
</style>